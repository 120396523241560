/* General Styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  scroll-behavior: smooth;
}

/* Navbar Styling */
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: black;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}

.navbar-content {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  justify-content: space-between;
}

.navbar-logo {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: auto; /* Ensures logo and company name align to the left */
  padding-left: 20px; /* Adds padding to push it closer to the edge */
}

.company-name {
  color: #ecf0f1;
  font-size: 1.2em;
  text-decoration: none;
}

.navbar-links {
  display: flex;
  gap: 20px;
  margin-left: auto;
}

.navbar-links li {
  list-style-type: none;
}

.navbar-links li a {
  color: #ecf0f1;
  text-decoration: none;
  font-size: 1em;
  transition: color 0.3s ease;
}

.navbar-links li a:hover,
.navbar-links li a.active {
  color: #f39c12;
}

/* Hamburger icon for mobile view */
.hamburger {
  display: none;
  font-size: 1.5em;
  color: #ecf0f1;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .navbar-links {
    display: none;
    position: absolute;
    top: 43px;
    left: 0;
    background-color: black;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 15px 0;
    gap: 10px;
  }

  .navbar-links.show {
    display: flex;
  }

  .hamburger {
    display: block;
  }
}

@media (min-width: 769px) {
  .hamburger {
    display: none;
  }
}




/* Hand Gesture */
.gesture {
  width: 50px;
  height: 50px;
  background-image: url('hand-gesture.webp'); /* Use the correct image path */
  animation: shake 1s infinite;
}

/* Animation for hand gesture */
@keyframes shake {
  0%, 100% { transform: rotate(0deg); }
  50% { transform: rotate(10deg); }
}

/* Hero Section */
.hero-section {
  height: 100vh;
  background: url('background2.png') no-repeat center center/cover;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 100px; /* Prevents overlap with fixed navbar */
  padding: 0 20px; /* Adds padding on sides for smaller screens */
}

.hero-section h1 {
  font-size: 3em;
  margin: 0;
}

.hero-section button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hero-section button:hover {
  background-color: #2980b9;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2.5em; /* Slightly smaller heading on tablets */
  }

  .hero-section button {
    padding: 8px 16px; /* Smaller button padding on tablets */
  }
}

@media (max-width: 500px) {
  .hero-section h1 {
    font-size: 2em; /* Smaller heading on mobile */
  }

  .hero-section {
    padding-top: 80px; /* Adjusts padding for mobile */
  }

  .hero-section button {
    padding: 8px 16px; /* Smaller padding for mobile button */
    font-size: 0.9em; /* Smaller font size on mobile */
  }
}


/* Section Styling */
.section {
  padding: 60px 20px;
}

.section h2 {
  text-align: center;
  margin-bottom: 40px;
}
/* //////////////////////// */

/* Services Section */
.services-section {
  background-color: #f4f4f4;
  padding: 50px 20px;
}

/* Services Cards Container */
.services-cards {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
  justify-content: center; /* Centers content */
  gap: 20px;
}

/* Individual Service Card */
.card {
  background: white;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  flex: 1 1 30%; /* Default 3 cards per row */
  max-width: 300px; /* Limits card width on larger screens */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

/* Hover Effect on Service Card */
.card:hover {
  transform: scale(1.05); /* Slightly enlarges card on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Increases shadow on hover */
}


/* Responsive Adjustments */
@media (max-width: 768px) {
  .card {
    flex: 1 1 45%; /* 2 cards per row on medium screens */
    max-width: none;
  }
}

@media (max-width: 500px) {
  .card {
    flex: 1 1 100%; /* 1 card per row on small screens */
  }

 
}




/* General Company Name */
.company-name {
  font-size: 1em;
  color: #ecf0f1;
  font-weight: bold;
  white-space: nowrap;
}

/* Upgrade Section Styling */
.upgrade-section {
  background-color: #f0f9f6;
  padding: 40px 20px;
  border-radius: 8px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: #333;
}

.upgrade-section:hover {
  transform: scale(1.03);
}

.upgrade-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #1a9e7b;
}

.upgrade-description {
  font-size: 1.1rem;
  margin-bottom: 30px;
  color: #555;
  padding: 0 10px; /* Improves readability on larger screens */
}

/* Upgrade Cards Container */
.upgrade-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 15px;
  padding: 0 10px;
}

/* Individual Upgrade Card */
/* Individual Upgrade Card */
.upgrade-card {
  background: #ffffff;
  padding: 20px;
  border-radius: 6px;
  width: calc(33% - 20px); /* 3 cards per row on large screens */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  color: #333;
  text-align: left;
  margin-bottom: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transition for smooth effect */
}

/* Hover Effect on Upgrade Card */
.upgrade-card:hover {
  transform: scale(1.05); /* Slightly enlarges card on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Increases shadow on hover */
}


/* Responsive Adjustments */
@media (max-width: 992px) {
  .upgrade-card {
    width: calc(50% - 20px); /* 2 cards per row on medium screens */
  }
}

@media (max-width: 600px) {
  .upgrade-card {
    width: 100%; /* 1 card per row on small screens */
  }
}

/* Card Text Styling */
.upgrade-card strong {
  color: #1a9e7b;
}

/* Footer and Contact Info */
.upgrade-footer, .upgrade-contact {
  font-size: 1.1rem;
  color: #555;
  margin-top: 20px;
  line-height: 1.6;
  padding: 0 10px;
}

.upgrade-footer {
  margin-bottom: 15px;
}

.upgrade-contact {
  font-weight: bold;
  color: #1a9e7b;
}


/* ////////////////////// */
/* Section Styling */
.testimonials-section {
  background-color: #f3f4f6; /* Light Pastel Background */
  padding: 4rem 2rem;
  text-align: center;
}

/* Heading and Subtitle */
.section-heading {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.section-subtitle {
  font-size: 1.1rem;
  color: #7d8c96;
  margin-bottom: 2rem;
}

/* Testimonials Container for Grid Layout */
.testimonials-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
}

/* Individual Testimonial Tile */
.testimonial {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-left: 4px solid #16a085; /* Teal Left Border */
  transition: transform 0.2s;
}

.testimonial:hover {
  transform: scale(1.03);
}

/* Quote and Cite Styling */
.testimonial-quote {
  font-size: 1.1rem;
  color: #333333;
  font-style: italic;
  margin-bottom: 0.5rem;
}

.testimonial-cite {
  font-size: 1rem;
  color: #16a085;
  font-weight: bold;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .section-heading {
    font-size: 2rem;
  }

  .section-subtitle {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  .testimonial {
    padding: 1rem;
  }

  .testimonials-container {
    gap: 1rem;
  }
}

@media (max-width: 480px) {
  .section-heading {
    font-size: 1.8rem;
  }

  .section-subtitle {
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }

  .testimonial-quote {
    font-size: 1rem;
  }

  .testimonial-cite {
    font-size: 0.9rem;
  }

  .testimonials-container {
    grid-template-columns: 1fr;
  }
}


/* ????????? */
/* General Contact Section Styling */
.contact-section {
  padding: 40px 20px;
  margin: 0 auto;
  text-align: center;
  color: #333;
}

.contact-section h2 {
  font-size: 2rem;
  color: #007bff; /* Blue color for heading */
  margin-bottom: 10px;
}

.contact-details p {
  font-size: 1em;
  margin: 5px 0;
}

.contact-details strong {
  color: #555;
}

/* Form Tile Styling */
.contact-form-tile {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: block;
  margin: 0 auto; /* Centers the button horizontally */
  max-width: 600px;
}

/* Form Fields */
.contact-form-tile form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-form-tile input[type="text"],
.contact-form-tile input[type="email"],
.contact-form-tile textarea {
  
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

.contact-form-tile input[type="text"]:focus,
.contact-form-tile input[type="email"]:focus,
.contact-form-tile textarea:focus {
  border-color: #007bff;
  outline: none;
}

.contact-form-tile textarea {
  resize: vertical;
  min-height: 100px;
}

/* Button Styling */
.contact-form-tile button[type="submit"] {
  padding: 10px;
  font-size: 1em;
  color: #ffffff;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form-tile button[type="submit"]:hover {
  background-color: #218838;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contact-section {
    padding: 20px 15px;
  }

  .contact-section h2 {
    font-size: 1.5rem;
  }

  .contact-form-tile {
    padding: 15px;
    max-width: 100%; /* Removes max-width restriction for smaller screens */
  }

  .contact-form-tile input[type="text"],
  .contact-form-tile input[type="email"],
  .contact-form-tile textarea {
    font-size: 0.9em;
    padding: 8px;
  }

  .contact-form-tile button[type="submit"] {
    font-size: 0.9em;
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .contact-section h2 {
    font-size: 1.3rem;
  }

  .contact-form-tile {
    padding: 10px;
  }

  .contact-details p {
    font-size: 0.9em;
  }
}


